// Import necessary modules
import { supabase } from './src/scripts/supabase.js';
import { checkAuth, saveSession } from './src/scripts/auth.js';
import { initializeHeader } from './src/scripts/header.js';
import { loadSideNav } from './src/scripts/sidebar-nav.js';

let modalOpenedFrom = null;
let isUserSubscribed = false;
let session = null;
let subscription = null;
let videosLoaded = false;

function toSentenceCase(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function getEnv(key, defaultValue) {
  if (window.ENV && window.ENV[key] !== undefined) {
    return window.ENV[key];
  }
  if (import.meta.env[key] !== undefined) {
    return import.meta.env[key];
  }
  return defaultValue;
}

let userData = null;
let isAuthenticated = false;
let isSubscribed = false;

function isIOS() {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

async function initializePage() {
  if (videosLoaded) return;

  try {
    // Check authentication, subscription, and user data
    // Check authentication and subscription status
    const authState = await checkAuth();
    const { session, subscription, userData } = authState;
    isAuthenticated = !!session;
    isSubscribed = !!subscription;

    // Handle display for non-subscribed users
    if (isAuthenticated) saveSession(session);

    // Initialize header and navigation with user data
    await initializeHeader(session, subscription, userData);
    const currentPage = 'index';
    await loadSideNav(currentPage, session, subscription, userData);

    // Set up the pricing button for non-subscribed users
    setupPricingButton(isSubscribed);

    // Load main content for the page
    await loadMainPageContent(session, subscription);

    // Setup handlers for state changes and modal
    setupPopStateHandler();
    await handleVideoInUrl();
    setupModalCloseButton();

    videosLoaded = true;
  } catch (error) {
    console.error('Error during page initialization:', error);
    showAlert('An error occurred while loading the page. Please try again.');
  }
}

function setupPricingButton(isSubscribed) {
  const pricingButtonHero = document.getElementById('pricingButtonHero'); // ID for the top button
  const pricingButtonVideos = document.getElementById('pricingButtonVideos'); // ID for the bottom button

  [pricingButtonHero, pricingButtonVideos].forEach(button => {
    if (button) {
      if (!isSubscribed) {
        // Display the button if the user does not have an active subscription
        button.style.display = 'block';
        button.addEventListener('click', () => {
          window.location.href = '/pricing';
        });
      } else {
        // Hide the button if the user has an active subscription
        button.style.display = 'none';
      }
    }
  });
}

async function loadMainPageContent(session, subscription) {
  const selectedPlatform = getSelectedPlatform();
  await loadVideos(session, subscription);
  updatePlatformSelection(selectedPlatform);
}

function setupPopStateHandler() {
  window.addEventListener('popstate', async (event) => {
    const modal = document.getElementById('modal');
    if (modal && !modal.classList.contains('hidden')) {
      closeModal(true);
    }

    const pathParts = window.location.pathname.split('/').filter(Boolean);
    if (pathParts.length === 1 && pathParts[0].length === 36) {
      await handleVideoInUrl();
    } else if (window.location.pathname === '/') {
      await loadMainPageContent();
    } else {
      await loadMainPageContent();
    }
  });
}

async function handleVideoInUrl() {
  const pathParts = window.location.pathname.split('/').filter(Boolean);
  if (pathParts.length === 1 && pathParts[0].length === 36) {
    const videoId = pathParts[0];

    try {
      let { data: video, error: flowError } = await supabase
        .from('flow_videos')
        .select(`
          *,
          apps:app_id (
            app_name,
            normalised_app_name,
            logo_url,
            description
          )
        `)
        .eq('video_id', videoId)
        .single();

      if (flowError || !video) {
        let { data: interactionVideo, error: interactionError } = await supabase
          .from('interaction_videos')
          .select(`
            *,
            apps:app_id (
              app_name,
              normalised_app_name,
              logo_url,
              description
            )
          `)
          .eq('video_id', videoId)
          .single();

        if (interactionError || !interactionVideo) {
          throw new Error('Video not found in either flow_videos or interaction_videos tables');
        }

        video = interactionVideo;
      }

      if (!video) {
        window.history.replaceState(null, '', '/');
        await loadMainPageContent();
        return;
      }

      if (document.getElementById('videosContainer').children.length === 0) {
        await loadMainPageContent();
      }

      await openModal(videoId, video.apps.normalised_app_name, '/');
    } catch (error) {
      console.error('Error fetching video:', error);
      window.history.replaceState(null, '', '/');
      await loadMainPageContent();
    }
  }
}

function setupModalCloseButton() {
  const closeModalButton = document.getElementById('closeModalButton');
  if (closeModalButton) {
    closeModalButton.addEventListener('click', (event) => {
      event.preventDefault();
      closeModal(false);
    });
  }
}

function getSelectedPlatform() {
  const pathParts = window.location.pathname.split('/').filter(part => part);
  return pathParts[0] || 'shots';
}

async function loadVideos(session, subscription) {
  const videosContainer = document.getElementById('videosContainer');

  if (!videosContainer) {
    console.error('Videos container is missing!');
    return;
  }

  if (videosContainer.children.length > 0) {
    videosContainer.innerHTML = '';
  }

  try {
    let allVideos = [];
    const limit = (session && subscription) ? 100 : 20;

    let { data: flowVideos, error: flowError } = await supabase
      .from('flow_videos')
      .select(`
        *,
        apps:app_id (
          app_name,
          normalised_app_name,
          logo_url,
          description
        )
      `)
      .order('added_on', { ascending: false })
      .limit(limit);

    if (flowError) throw flowError;
    allVideos = allVideos.concat(flowVideos);

    let { data: interactionVideos, error: interactionError } = await supabase
      .from('interaction_videos')
      .select(`
        *,
        apps:app_id (
          app_name,
          normalised_app_name,
          logo_url,
          description
        )
      `)
      .order('added_on', { ascending: false })
      .limit(limit);

    if (interactionError) throw interactionError;
    allVideos = allVideos.concat(interactionVideos);

    allVideos = allVideos.sort(() => 0.5 - Math.random());

    if (!session || !subscription) {
      allVideos = allVideos.slice(0, 20);
    }

    const fragment = document.createDocumentFragment();
    allVideos.forEach(video => {
      const videoElement = createVideoElement(video);
      if (videoElement) {
        fragment.appendChild(videoElement);
      }
    });
    videosContainer.appendChild(fragment);

    // Use Intersection Observer to load videos as they come into view
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const videoElement = entry.target.querySelector('video');
          if (videoElement) {
            videoElement.src = videoElement.dataset.src;
            videoElement.load();
            observer.unobserve(entry.target);
          }
        }
      });
    }, { rootMargin: '100px' });

    document.querySelectorAll('.video-item').forEach(item => observer.observe(item));

  } catch (error) {
    console.error('Error loading videos:', error);
    videosContainer.innerHTML = `<p class="text-center col-span-full">Error loading videos. Please try again later.</p>`;
  }
}

function createVideoElement(data) {
  const { video_url, apps, video_id } = data;
  if (!video_url) {
      console.error('Video URL missing for videoId:', video_id);
      return null;
  }

  const appName = apps?.app_name ? toSentenceCase(apps.app_name) : 'Unknown Company';
  const appLogo = apps?.logo_url || 'path/to/default/logo.png';
  const appSlug = apps?.normalised_app_name || appName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  const appUrl = `${window.location.origin}/${appSlug}`;

  const videoItem = document.createElement('div');
  videoItem.className = 'video-item grid-hover cursor-pointer';
  videoItem.innerHTML = `
      <div class="flex justify-center items-center w-full rounded-3xl bg-stone-100 h-72 p-4 relative">
          <div class="skeleton-loader absolute inset-4 flex items-center justify-center bg-stone-300 rounded-2xl animate-pulse">
              <svg class="w-10 h-10 text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z"/>
              </svg>
              <span class="sr-only">Loading...</span>
          </div>
          <video data-src="${video_url}" class="rounded-2xl w-auto object-cover opacity-0 transition-opacity duration-300" style="height: 100%;" muted playsinline preload="metadata" controlslist="nodownload noplaybackrate" disablePictureInPicture></video>
      </div>
      <div class="flex items-center text-sm app-link mt-2.5" data-app-url="${appUrl}">
          <img src="${appLogo}" class="w-6 h-6 rounded-full object-cover border border-stone-100 mr-1.5">
          <span class="font-medium text-sm hover:underline">${appName}</span>
      </div>
  `;
  videoItem.dataset.videoId = video_id;

  const appLinkElement = videoItem.querySelector('.app-link');
  appLinkElement.addEventListener('click', (e) => {
      e.preventDefault();
      navigateToCompanyPage(appUrl);
  });

  const videoElement = videoItem.querySelector('video');
  const skeletonLoader = videoItem.querySelector('.skeleton-loader');

  videoElement.addEventListener('loadeddata', () => {
      skeletonLoader.style.opacity = '0';
      videoElement.style.opacity = '1';
      setTimeout(() => {
          skeletonLoader.remove();
      }, 300);
  });

  videoElement.addEventListener('error', () => {
      console.error(`Error loading video (${video_id}):`, videoElement.error);
      videoItem.remove();
  });

  setupVideoInteractions(videoItem, video_id);
  return videoItem;
}

function navigateToCompanyPage(appUrl) {
  localStorage.setItem('previousUrl', window.location.pathname);
  window.location.href = appUrl;
}

async function setupVideoInteractions(videoItem, videoId) {
  const videoElement = videoItem.querySelector('video');
  let isPlaying = false;

  if (!isIOS()) {
      const playVideo = () => {
          if (!isPlaying && videoElement.readyState >= 2) {
              videoElement.play().then(() => {
                  isPlaying = true;
              }).catch(error => {
                  if (error.name !== 'AbortError') {
                      console.error("Error playing video on hover: ", error);
                  }
              });
          }
      };

      videoItem.addEventListener('mouseover', playVideo);
      videoItem.addEventListener('touchstart', playVideo, { passive: true });

      const pauseVideo = () => {
          if (isPlaying) {
              videoElement.pause();
              videoElement.currentTime = 0;
              isPlaying = false;
          }
      };

      videoItem.addEventListener('mouseleave', pauseVideo);
      videoItem.addEventListener('touchend', pauseVideo);
  }

  // Add click event listener to the video element
  videoElement.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      openModal(videoId, videoItem.dataset.appSlug, window.location.pathname);
  });

  // Add touch event listener for mobile devices
  videoElement.addEventListener('touchend', (event) => {
      if (!isPlaying) {
          event.preventDefault();
          openModal(videoId, videoItem.dataset.appSlug, window.location.pathname);
      }
  });

  videoElement.addEventListener('contextmenu', (event) => {
      event.preventDefault();
  });

  // Remove download option
  videoElement.controlsList = "nodownload noplaybackrate";
}

function updatePlatformSelection(selectedPlatform) {
  const platformTabs = document.querySelectorAll('.platform-tab');
  platformTabs.forEach(tab => {
    const isSelected = tab.dataset.platform === selectedPlatform;
    tab.classList.toggle('border-color-primary', isSelected);
    tab.classList.toggle('text-color-primary', isSelected);
    tab.classList.toggle('font-bold', isSelected);
    tab.classList.toggle('border-transparent', !isSelected);
    tab.classList.toggle('text-gray-500', !isSelected);
    tab.classList.toggle('hover:border-gray-200', !isSelected);
    
    tab.dataset.selected = isSelected.toString();
  });
}

async function openModal(videoId, appSlug, originalUrl = null) {
  const modal = document.getElementById('modal');
  const modalVideo = document.getElementById('modalVideo');
  const modalAppName = document.getElementById('modalAppName');

  if (!modal || !modalVideo || !modalAppName) {
    console.error('Essential modal elements are missing!');
    return;
  }

  try {
    let { data: video, error: flowError } = await supabase
      .from('flow_videos')
      .select(`
        *,
        apps:app_id (
          app_name,
          normalised_app_name,
          logo_url,
          description
        )
      `)
      .eq('video_id', videoId)
      .single();

    if (!video || flowError) {
      let { data: interactionVideo, error: interactionError } = await supabase
        .from('interaction_videos')
        .select(`
          *,
          apps:app_id (
            app_name,
            normalised_app_name,
            logo_url,
            description
          )
        `)
        .eq('video_id', videoId)
        .single();

      if (!interactionVideo || interactionError) {
        throw new Error('Video not found in both tables');
      }

      video = interactionVideo;
    }

    const { video_url, platform, apps } = video;
    const { app_name, logo_url } = apps;
    const formattedAppName = toSentenceCase(app_name);

    if (!video_url) {
      throw new Error('No video URL found.');
    }

    appSlug = appSlug || apps.normalised_app_name;

    modalVideo.src = video_url;
    modalVideo.load();
    modalVideo.controlsList = isUserSubscribed ? "noplaybackrate" : "nodownload noplaybackrate";

    modalVideo.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      return false;
    });

    modalAppName.href = `/${appSlug}`;
    modalAppName.innerHTML = `<img src="${logo_url}" class="w-9 h-9 rounded-md object-cover bg-stone-100 mr-2" alt="${formattedAppName} Logo">${formattedAppName}`;
    
    modal.classList.remove('hidden');
    document.body.style.overflow = 'hidden';

    modalVideo.classList.toggle('video-fit-modal', platform === 'android' || platform === 'ios');

    const newUrl = `/${videoId}`;

    modalOpenedFrom = originalUrl || window.location.pathname;

    history.pushState(
      { 
        modalContext: {
          videoId,
          appSlug,
          originalUrl: modalOpenedFrom
        }
      },
      '',
      newUrl
    );
  } catch (error) {
    console.error('Error fetching video:', error);
    alert('Failed to load video. Please try again later.');
  }
}

function closeModal(isPopState = false) {
  const modal = document.getElementById('modal');
  const modalVideo = document.getElementById('modalVideo');
  const loader = document.querySelector('.loader');

  // Pause the video if it's playing
  if (modalVideo) modalVideo.pause();
  if (modal) modal.classList.add('hidden');
  if (loader) loader.classList.add('hidden'); // Hide loader when closing modal
  document.body.style.overflow = 'auto';

  if (!isPopState && modalOpenedFrom) {
    history.pushState(null, '', modalOpenedFrom);
    modalOpenedFrom = null;
  } else if (window.location.pathname.includes('/')) {
    history.pushState(null, '', '/');
  }
}

function setupPlatformLinks() {
  const platformLinks = document.querySelectorAll('.platform-link');

  platformLinks.forEach(link => {
    link.addEventListener('click', (event) => {
      event.preventDefault();

      const platform = link.getAttribute('data-platform');

      if (!platform) {
        console.error('Platform not found in the data attribute');
        return;
      }

      const newUrl = `/browse/${platform}/flows`;
      window.location.href = newUrl;
    });
  });
}

document.addEventListener('click', (event) => {
  const modal = document.getElementById('modal');
  if (modal && !modal.classList.contains('hidden') && event.target === modal) {
    closeModal(false);
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const hamburgerMenuIcon = document.getElementById('hamburgerMenuIcon');
  const sidebar = document.getElementById('sidebar');
  const closeSidebarButton = document.getElementById('closeSidebarButton');
  const sidebarOverlay = document.getElementById('sidebarOverlay');

  function openSidebar() {
      sidebar.classList.remove('translate-x-full');
      sidebar.classList.add('translate-x-0');
      sidebar.classList.remove('hidden');
      if (sidebarOverlay) {
          sidebarOverlay.classList.remove('hidden');
      }
      hamburgerMenuIcon.classList.add('hidden');
      closeSidebarButton.classList.remove('hidden');
      document.body.style.overflow = 'hidden';
  }

  function closeSidebar() {
      sidebar.classList.remove('translate-x-0');
      sidebar.classList.add('translate-x-full');
      setTimeout(function() {
          sidebar.classList.add('hidden');
      }, 300);
      if (sidebarOverlay) {
          sidebarOverlay.classList.add('hidden');
      }
      hamburgerMenuIcon.classList.remove('hidden');
      closeSidebarButton.classList.add('hidden');
      document.body.style.overflow = '';
  }

  if (hamburgerMenuIcon) {
      hamburgerMenuIcon.addEventListener('click', function() {
          openSidebar();
      });
  }

  if (sidebarOverlay) {
      sidebarOverlay.addEventListener('click', function() {
          closeSidebar();
      });
  }
});

document.addEventListener('DOMContentLoaded', async () => {
  await initializePage();
  await initializeHeader();
  
  setupPlatformLinks();
});